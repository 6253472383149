import { browserEqualsTo } from "@/js/other/helper";
import { jqToHTML } from "@other/helper";

export function replaceProducts(content) {
  const products = content.find(".products");
  const noResults = content.find(".no-result");

  if (noResults.length) return $(".products").html(jqToHTML(noResults));

  $(".products").replaceWith(products);
}

export function addProducts(content) {
  const products = content.find(".products");
  const noResults = content.find(".no-result");

  if (noResults.length) return $(".products").html(jqToHTML(noResults));

  $(".products").append(products.children());
}

export function replacePagination(content) {
  const pagination = content.find(".pagination");

  if (!pagination.length) return $(".pagination").html("");
  if ($(".pagination").length) $(".pagination").replaceWith(pagination);
  else pagination.insertAfter($(".products"));
}

export function replaceOtherContent(content) {
  const productNames = content.find(".product-names");
  const filterBtns = content.find(".filter-button__wrapper");

  $(".product-names").replaceWith(productNames);
  $("main .filter-button__wrapper").replaceWith(filterBtns);
}

export function replaceFilters(content) {
  const filters = content.find(".filters div[data-tagname]");

  const sortedFilters = sortDOMFiltersItems(filters);
  [...sortedFilters].forEach((filter) => {
    $(`.filters div[data-tagname=${filter.dataset.tagname}`).replaceWith(filter);
  });
}

function sortDOMFiltersItems(filters) {
  const multiplier = browserEqualsTo("firefox") ? -1 : 1;
  const sortFn = (a, _) => (a.firstElementChild.checked ? multiplier * -1 : multiplier * 1);
  const filterFn = (label) => label.firstElementChild.disabled;
  const appendFn = (label) => label.parentNode.appendChild(label);

  Array.from(filters).forEach((parentNode) => {
    const labels = [...parentNode.querySelectorAll("label")];  
    labels.sort(sortFn).forEach(appendFn);
    labels.filter(filterFn).sort(sortFn).forEach(appendFn);
  });

  return filters;
}