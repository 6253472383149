import Select from "@/UI/Select";

const Sort = Select({
  name: "sort",
  template: {
    currentOption: `
      <div data-option="current">
        <svg width="24" height="24">
          <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#sort"></use>
        </svg>
        <span>Стандартное значение</span>
      </div>
    `,
  }
});

if (Sort) Sort.on("change", ({ value }) => {
  const url = new URL(location);
  
  if (!value) url.searchParams.delete("sort");
  else url.searchParams.set("sort", value);

  window.history.replaceState(null, null, decodeURIComponent(url));
});

export { Sort };