import { useFetchClient } from "@/js/other/useFetchClient";
import { epDto, orgDto, loginDto } from "./dto";
import { mapResponse } from "@/js/repositories/auth/mapper";

const client = useFetchClient(location.origin);

export class AuthRepository {
  /**
   * 
   * @param {epDto|orgDto} dto Данные для регистрации пользователя
   * @returns {Promise<{ data, status }>}
   */
  static async signUp(dto) {
    // TODO: деструктурировать ответа и если type == error, то сделать статус error
    return await client.post("/netcat/add.php", dto);
  }
  /**
   * 
   * @param {loginDto} dto Данные авторизации пользователя
   * @returns {Promise<{ data, status }>}
   */
  static async login(dto) {
    const response = await client.post("/netcat/modules/auth/", dto);
    return mapResponse(response);
    
  }
  /**
   * 
   * @param {recoverPasswordDto} dto Данные авторизации пользователя
   * @returns {Promise<{ data, status }>}
   */
  static async recoverPassword(dto) {
    const response = await client.post("/netcat/modules/auth/restore_password.php", dto);
    return mapResponse(response);
  }
}