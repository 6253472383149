import { default as SelectInstance } from "./core/select.js";

/**
 * 
 * @param {object} opts
 * @param {string} opts.name Название селекта [data-select="{name}"]
 * @param {string} opts.parentSelector Селектор родителя, в котором будем искать селекты
 * @param {boolean} opts.disabled
 * @param {object} opts.template Верстка для некоторых элементов селекта
 * @param {string} opts.template.arrow Верстка для стрелки
 * @param {string} opts.template.currentOption Верстка для выбранного элемента
 * @param {object} vars Record<key, value>
 * @returns {SelectInstance}
 */
export default function Select(opts = {}) {
  if (opts.name) {
    const $el = document.querySelector(`[data-select='${opts.name}']`);
    if (!$el) {
      return console.warn(`There is no select with name '${opts.name}'`);
    }

    const _select = new SelectInstance($el, opts);
    return _select;
  } else {
    let { parentSelector = "body" } = opts;
    const $els = [...document.querySelectorAll(`${parentSelector} [data-select]:not(.inited)`)];
    return $els.map(($el) => {
      $el.classList.add("inited");
      return new SelectInstance($el, opts);
    });
  }  
}

export { Select }