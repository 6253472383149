import Slider from "@libs/slider/src/js/app.js";

/**
 * @param {String} selector Селектор родительского элемента слайдера
 * @returns Экзмепляр слайдера
 */
export default function createAdSlider(selector) {
  const root = document.querySelector(selector);
  if (!root) return;

  const slider = Slider({
    root,
    autoplay: true,
    loop: true,
    delay: 3500,
    speed: 200,
    pagination: false,
  });

  if (!slider) return;

  slider.root.addEventListener("click", (event) => {
    if (event.target.dataset.action !== "closeBanner") {
      return;
    }

    slider.destroy();
  });
  
  return slider;
}