import { createModal } from "@/js/components/modal";

const thanksModal = createModal({
  selector: "thanks",
  titleCenter: true,
});

document.addEventListener("modal:thanks", ({ context }) => {
  const { title, content } = context
  thanksModal.setTitle(`
    <div class="xs:min-w-[330px]">${title}</div>
  `);
  thanksModal.setContent(content?.trim() ? `
    <div class="block xs:min-w-[330px] -mt-4 text-xl text-grey text-center leading-8">${content}</div>  
  ` : "");
  thanksModal.open();
});