import { raf } from "@/js/other/raf";
import "./index.scss";

export function TextfieldAnimated() {
  const hanldeChange = (event) => {
    const parent = event.target.closest(".textfield__wrapper");
    if (!parent) return;
    
    const span = parent.querySelector("span");
    if (!span) return;
    
    raf(() => {
      span.classList.toggle("js-filled", !!event.target.value)
    });
  }

  document.addEventListener("input", hanldeChange);
  document.addEventListener("change", hanldeChange);
}

export const getTextfieldTemplate = ({
  id = "",
  type = "text",
  placeholder = "",
  className = "",
  labelClass = "",
  name = "name",
  value = "",
  disabled = false,
  data = {}
}) => {
  return `
    <label class='textfield__wrapper ${labelClass}' for='${id}'>
      <input
        id='${id}'
        class='textfield ${className}'
        type='${type}'
        name='${name}'
        value='${value}'
        ${disabled ? "disabled" : ""}
        ${Object.keys(data).map((key) => `data-${key}="${data[key]}"`).join(" ")}
      />
      <span>${placeholder}</span>
    </label>
  `;
}