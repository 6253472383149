import debounce from 'lodash.debounce';
import {getAxios} from '@other/axios';
import {normalizeKeymap} from '@other/helper';
import { getCookie, setCookie, deleteCookie } from '@other/cookies';

export function makeSearch(options) {
  const root = $(options.root);
  const innpuut = root.find(options.input ? options.input : 'input')
  const iicons = root.find('.search__icons')[0] ? root.find('.search__icons') : root.find('[data-action="clearInput"]');
  const ffform = $(options.form)
  const resultProducts = options.result ? $(options.result) : root.find('[data-result-products]');
  const resultBrands = root.find("[data-result-brands]");
  const minLength = options.minLength || 3
  const listeners = []
  const hideShowMore = options.hideShowMore;
  const showMoreLink = options.showMoreLink || "/catalog/?find=";
  let brands = null;
  let query = '';
  let isLoading = false;
  let resultReady = false;
  let isKeysAleradyListened = false;
  let historyCookies = null;

  const fetchDebounce = debounce(() => {
    if (query.length >= minLength) {
      isLoading = true
      getAxios()
          .then((axios) => axios.get(options.url.replace('%QUERY', encodeURIComponent(query)))
              .then(({data}) => {
                isLoading = false
                updateResult(data, query);

                if (innpuut.val().trim()) {
                  ffform.addClass('js-open')
                }
                ffform.removeClass('js-loading')
              })
          )
    } else {
      clearResult();
      ffform.removeClass('js-loading');
      ffform.removeClass('js-no-result');
    }
  }, 500)

  const makeMarkup = (data, query) => {
    let result = typeof data === 'string' ? '' : data.slice(0, 10).reduce((acc, cur) => {
      let div_inner;
      if (cur.clickable) {
        if (cur.link) {
          div_inner = `<a class="item flex items-center p-3 rounded-md hover:bg-grey-light2" href="${cur.link}"><span>${cur.html}</span></a>`
        } else {
          div_inner = `<span class="item flex items-center p-3 rounded-md hover:bg-grey-light2 submit_on_click"><span>${cur.html}</span></span>`
        }
      } else {
        div_inner = `<span class="flex items-center p-3 rounded-md hover:bg-grey-light2">По вашему запросу ничего не найдено</span>`
      }

      return acc += `${div_inner}`
    }, '');

    if (!result) {
      return '';
    }
    
    if (data.length >= 10 && !hideShowMore) {
      result += `
        <a href="${showMoreLink}${query}" class="group/more flex items-center justify-center p-3 gap-2 hover:text-black">
          Показать все результаты
          <svg class="stroke-grey group-hover/more:stroke-red" width="16" height="16">
            <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#arrow"></use>
          </svg>
        </a>
      `;
    }

    return result;
  }

  const handleHistoryContent = function() {
    const history_content = root.find("[data-history]");
    historyCookies = getCookie("search_history")?.split("#").filter((item) => item.trim());
    let template = "";

    if (!historyCookies || historyCookies.length === 0) {;
      ffform.removeClass("js-history");
      return;
    }

    historyCookies.forEach((value, id) => {
      template += `
        <a href="/catalog/?find=${value}" class="flex items-center p-3 rounded-md hover:bg-grey-light2 gap-4">
          <svg class="stroke-grey-medium" width="16" height="16">
            <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#clock"></use>
          </svg>
          ${value}
          <svg class="ml-auto cursor-pointer stroke-grey-medium hover:stroke-red" width="16" height="16" data-action="removeHistoryItem" data-id="${id}">
            <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#close"></use>
          </svg>
        </a>
      `;
    });
    
    history_content.html(template);
    ffform.addClass("js-open js-history");
  }

  function showBrands(query) {
    const enToRuQuery = normalizeKeymap(query);
    const ruToEnQuery = normalizeKeymap(query, true);
    const offset = query.split(" ").filter(Boolean).length;

    let filteredBrands = brands.filter((brand) => {
      let check = false;
      const items = brand.Name.toUpperCase().split(" ").filter(Boolean);

      for (let i = 0; i < items.length; i++) {
        const sliced = items.slice(i, i + offset);
        const word = sliced.join(" ");
        check = compareWords(word, query) || compareWords(word, enToRuQuery) || compareWords(word, ruToEnQuery);

        if (check) {
          break;
        }
      }
      
      return check;
    }).slice(0, 3);

    if (!(filteredBrands && filteredBrands.length > 0)) {
      ffform.removeClass("js-has-brands");
      return;
    }

    ffform.removeClass("js-history");
    ffform.addClass("js-has-brands");

    const template = filteredBrands.reduce((html, brand) => {
      html += `<a class="flex items-center p-3 rounded-md hover:bg-grey-light2 gap-4" href="/proizvoditeli/${brand.Keyword}/">
        ${getBrandImage(brand)}
        <div class="flex flex-col">
          <span class="text-base">${brand.Name}</span>
          <span class="text-[1.1rem] text-grey">Бренд</span>
        </div> 
      </a>`;

      return html;
    }, "");

    $(resultBrands).html(template);
  }

  function compareWords(word1, word2) {
    const _filtered = word2.toUpperCase().split(" ").filter(Boolean);
    return word1 === _filtered.join(" ");
  }

  function getBrandImage(brand) {
    if (brand.logo == -1) {
      return "";
    }

    if (!brand.logo) {
      return `<div class="flex items-center justify-center shrink-0 w-20">
        <svg class="fill-grey-light" width="24" height="24">
          <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#brand"></use>
        </svg>
      </div>`;
    }

    return `<div class="flex items-center justify-center w-20">
      <img
        src="${brand.logo}"
        class="max-w-full max-h-full object-contain"
        onerror="this.src='/img/icons/brand.svg'"
      >
    </div>`;
  }

  const historyControls = {
    removeHistoryItem(button) {
      historyCookies.splice(+button.dataset.id, 1);
      setCookie("search_history", historyCookies.join("#"));
      handleHistoryContent();
    },
    removeHistory() {
      deleteCookie("search_history");
      ffform.removeClass("js-open js-history");
    }
  };

  const window_click = function(event) {
    const actionName = event.target.dataset.action;
    const action = historyControls[actionName];

    if (event.target.closest(".search__form") !== innpuut[0].closest(".search__form")) {
      ffform.removeClass("js-open js-history js-no-result js-result");
      return;
    }

    if (innpuut[0].value.trim() === "") {
      ffform.removeClass("js-result js-no-result");
      handleHistoryContent();
    }

    if (!actionName || !action) {
      return;
    }
    
    event.preventDefault();
    action(event.target);
  }

  const window_load = () => {
    if (innpuut.val().length) {
      ffform.addClass('js-active-clear')
    }
  }

  const inp_input_click = async (e) => {    
    const target = e.target;
    query = target.value.trim();

    if (query.length >= 2) {
      if (!brands) {
        brands = await fetch("/proizvoditeli/?isNaked=1")
          .then((res) => res.json())
          .then((data) => data.data);
      }
      showBrands(query);
    } else {
      ffform.removeClass("js-has-brands");      
    }
    
    if (query.length) {
      ffform.addClass('js-active-clear')

      if (target._tippy) {
        target._tippy.hide();
      }

      if (query.length >= minLength && !options.noFetch) {
        ffform.addClass('js-loading')
      }
    } else {
      clearResult();
      ffform.removeClass('js-active-clear js-result js-no-result');
      ffform.addClass('js-open');
      handleHistoryContent();
    }

    if (options.onChange) {
      options.onChange(query);
    }

    if (!options.noFetch) {
      fetchDebounce();
    }
  }

  const inp_click = ({target}) => {
    if (target.value.trim()) {
      ffform.addClass('js-open');
    }
  }

  const inp_focus = () => {
    if (!isLoading) {
      listenKeys()
    }
  }

  const body_click = () => {
    ffform.trigger('submit')
  }

  const form_submit = (e) => {
    e.preventDefault()

    if (innpuut.val().trim()) {
      options.onSubmit(innpuut.val().trim())
    } else {
      if (innpuut[0]._tippy) {
        innpuut[0]._tippy.show()
      }
    }
  }

  const icons_click = () => {
    clearResult()

    if (options.onClear) {
      options.onClear('')
    }

    innpuut.val('');
    innpuut[0].focus();
    ffform.removeClass('js-active-clear');
    ffform.removeClass('js-loading');
    ffform.removeClass("js-has-brands");
    if (options.onUpdate) {
      options.onUpdate(root);
    }
    if (options.onChange) {
      options.onChange('')
    }
  }
  
  window_load();
  $(window).on('load', window_load)
  $(window).on('click', window_click)
  innpuut.on('input click changed', inp_input_click)
  innpuut.on('click', inp_click)
  innpuut.on('focus', inp_focus)
  innpuut.on('blur', noListenKeys)
  ffform.on('submit', form_submit)
  iicons.on('click', icons_click)
  $(document.body).on('click', '.search_bar__search .submit_on_click', body_click)

  listeners.push(() => {
    innpuut.off('input click', inp_input_click)
    innpuut.off('click', inp_click)
    innpuut.off('focus', inp_focus)
    innpuut.off('blur', noListenKeys)

    ffform.off('submit', form_submit)
    iicons.off('click', icons_click)

    $(window).off('load', window_load)
    $(window).off('load', window_load)
    $(window).off('click', window_click)
    $(document.body).off('click', '.search_bar__search .submit_on_click', body_click)
  })

  function listenKeys() {
    if (!isKeysAleradyListened) {
      $(document).on('keydown', onFocus)
      isKeysAleradyListened = true
    }
  }

  function noListenKeys() {
    $(document).off('keydown', onFocus)
    isKeysAleradyListened = false
  }

  function onFocus(e) {
    if (!resultReady) return;
    if (e.keyCode == '38') { // top arrow
      onUpOrDown()
    }
    else if (e.keyCode == '40') { // down arrow
      onUpOrDown(true)
    }

    function onUpOrDown(isUp = false) {
      let focused = resultProducts.find('.item.focused')
      const hasFocused = focused.length

      if (!hasFocused) {
        focused = resultProducts.find('.item').first()
      }

      if (isUp) {
        blurLink()
        if (hasFocused) {
          if (focused.next().length && !focused.next().hasClass("group/more")) {
            focused = focused.next()
          }
        }

        focusLink()
      } else {
        blurLink()
        if (focused.prev().length && focused.prev().index() >= 0) {
          focused = focused.prev()
          focusLink()
        }
      }

      const focusedOffset = focused.length ? focused[0].offsetTop : false
      const resultScroll = resultProducts[0].scrollTop

      const shouldDoSomething = isUp ? focusedOffset - resultScroll > 180 : focusedOffset - resultScroll < 180

      if (focusedOffset && shouldDoSomething) {
        const arrOFnumbers = Array
            .from(resultProducts.find('.item'))
            .map((x) => parseFloat(getComputedStyle(x, null).height.replace('px', '')))

        const heightToScroll = arrOFnumbers.reduce((a, b) => (a + b)) / arrOFnumbers.length

        if (isUp) {
          resultProducts[0].scrollTop += heightToScroll
        } else {
          resultProducts[0].scrollTop -= heightToScroll
        }
      }

      function focusLink() {
        focused.addClass('focused bg-grey-light2')
      }

      function blurLink() {
        focused.removeClass('focused bg-grey-light2')
      }
    }
  }

  function updateResult(data, query) {
    if (data.length) {
      data = data.map(({title, link}) => {
        let regx = ''
        const subquries = query.split(' ')

        subquries.forEach((x) => {
          x = x.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
          regx += `${x}|${normalizeKeymap(x)}|`
        })

        regx = regx.slice(0, -1)
        regx = new RegExp(regx, 'ig')

        return {
          title, link,
          html: title.replace(regx, '<span class="bold">$&</span>'),
          clickable: true,
        }
      })

      ffform.removeClass('js-no-result js-history')
      ffform.addClass('js-result')
      resultReady = true
      listenKeys()
    }

    else {
      if (innpuut.val().trim()) {
        ffform.addClass('js-no-result')
        ffform.removeClass('js-history')
      }
      resultReady = false
      ffform.removeClass('js-result')
      noListenKeys()
    }

    resultProducts.html(makeMarkup(data, query));
    if (options.onUpdate) {
      options.onUpdate(root);
    }
  }

  function clearResult() {
    resultProducts.html('')
    resultBrands.html("");
    resultReady = false
  }

  return {
    setDefault() {
      innpuut.val('')
      resultProducts.html('')
      resultBrands.html("");
      ffform.removeClass('js-loading')
      ffform.removeClass('js-result')
      ffform.removeClass('js-active-clear')
    },
    destroy() {
      listeners.forEach((f) => f())
    },
  }
}