import axios from 'axios';

export function getAxios() {
  // return import(/* webpackChunkName: "axios" */ 'axios');
  return new Promise((resolve) => {
    resolve(axios);
  });
}

/**
 * 
 * @param {Object} params Объект с данным в формате ключ-значение
 * @description Добавляет данные входящего объекта в экземпляр объекта URLSearchParams
 * @returns URLSearchParams с данными
 */
export function getPostParams(params) {
  const postParams = new URLSearchParams();

  for (const key in params) {
    postParams.append(key, params[key]);
  }

  return postParams;
}