import { useFetchClient } from "@/js/other/useFetchClient";

const client = useFetchClient();

export class CatalogService {
  /**
   * 
   * @param {string} offer Tells when it's default catalog page or catalog sale page, for example 
   * @returns {Promise<{
   *  status: "error" | "success"
   *  data: {
   *    catalog_banner: string,
   *  }[]
   * }>}
   */
  static async getBanner(offer = '') {
    const { status, data } = await client.get("/netcat/partial.php", {
      offer,
      partial: "catalog_banner",
      json: 1
    });

    return {
      status,
      data: data?.catalog_banner,
    };
  }
}