/**
 * 
 * @param {*} name Cookie name
 * @returns Cookie value { String }
 */
export function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ));
  return matches ? decodeURIComponent(matches[1]).replace(/\+/g, " ") : undefined;
}

export function getAllCookies() {
  const result = {};
  document.cookie.split(';').forEach((item) => {
    const [name, value] = item.trim().split("=");
    result[name] = value;
  });
  return result;
}

export function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (const optionKey in options) {
    updatedCookie += '; ' + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

export function deleteCookie(name, options = {}) {
  setCookie(name, '', {
    'max-age': -1,
    expires: "2004-04-26T12:07:05.587Z",
    ...options,
  });
}