/**
 * Author: Рифат
 * Description: Логика взаимодействия с товаром: добавление в корзину\комментария, закладки, уведомление о поступлении,
 *              Подгрузка поставщиков, аналогов,
 */

import "@css-components/Product/product_new.scss";

window.addEventListener("load", (e) => {
  setTimeout(() => {
    import("./product.main.js");
    // 50 чтобы метрика не цеплялась
  }, 50);
});

if (document.querySelector(".Page-shop_cart")) {
  import("./product.cart.js");
}

if (document.querySelector(".Page-order_list")) {
  import("./product.order-list.js");
}