import { Props } from "tippy.js";

/**
 * 
 * @param {string} selector
 * @param {tooltipCallback} render Если данная функция вернет false, то тултип не покажется
 * @param {Props} options
 */
export function Tooltip(selector, render = () => {}, { onShow, ...options } = {}) {
  import(/* webpackChunkName: "tippy" */ "@parts/tippy.js").then(({ delegate, hideAll }) => {
    delegate("body", {
      target: selector,
      theme: "tailwindcss",
      zIndex: 25,
      delay: [0, 0],
      interactiveBorder: 20,
      allowHTML: true,
      appendTo: () => document.body,
      onShow: (instance) => {
        onShow && onShow(instance);
        const { reference, setContent, hide, hideAll, setProps } = instance;
        return render({ triggerEl: reference, setContent, hide, hideAll, setProps });
      },
      ...options,
    });
  });
}

/**
 * @callback tooltipCallback
 * @param {object} callbackProps
 * @param {HTMLElement} callbackProps.triggerEl Элемент, на который кликнули
 * @param {Function} callbackProps.setContent Установить контент для popover
 * @param {Function} callbackProps.hide Скрыть tooltip 
 * @param {Function} callbackProps.hideAll Скрыть все tooltip 
 * @param {Function} callbackProps.setProps Обновить конфигурацию тултипа 
 */