import 'lazysizes'

lazySizes.cfg.expand = 10
lazySizes.cfg.loadMode = 1

// ленивая загрузка для картинок вставленные в фон
document.addEventListener('lazybeforeunveil', ({target}) => {
  const bg = target.getAttribute('data-bg')

  if (bg) {
    target.style.backgroundImage = 'url(' + bg + ')'
  }
})