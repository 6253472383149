import { Emitter } from "@other/emitter.js";

class Search extends Emitter {
  constructor(input, options) {
    super();
    this.input = input;

    if (!this.input) {
      throw `Input: ${this.input} - Необходимо передать HTMLElement input`;
    }

    this.container = input.closest(options?.container || ".input__wrapper");
    if (!this.container) {
      throw `Container ${this.container} - Необходимо указать родительский контейнер ".input__wrapper"`;
    }

    this.buttons = {
      clear: this.container.querySelector("[data-action='clear']"),
      search: this.container.querySelector("[data-action='search']"),
    };

    this.on("clear", this.clearInput.bind(this));
    this.on("setValue", (value) => {
      this.input.value = value;
    });

    this.bindEvents();
  }
  bindEvents() {
    this.container.addEventListener("submit", this.handleSubmit.bind(this));
    this.container.addEventListener("click", this.handleClick.bind(this));
    this.input.addEventListener("input", this.handleInput.bind(this));
    this.input.addEventListener("change", this.handleChanged.bind(this));

    let eventName = /Android|iPhone/i.test(navigator.userAgent) ? "touchstart" : "click";
    this.container.addEventListener(eventName, this.handleClick.bind(this));
  }
  unbindEvents() {
    this.container.removeEventListener("submit", this.handleSubmit.bind(this));
    this.container.removeEventListener("click", this.handleClick.bind(this));
    this.input.removeEventListener("input", this.handleInput.bind(this));
    this.input.removeEventListener("change", this.handleChanged.bind(this));

    let eventName = /Android|iPhone/i.test(navigator.userAgent) ? "touchstart" : "click";
    this.container.removeEventListener(eventName, this.handleClick);
  }
  handleSubmit(event) {
    this.emit("submit", event, this);
  }
  handleChanged(event) {
    this.emit("change", event, this);
  }
  handleClick(event) {
    const target = event.target;
    const actionName = target?.dataset?.action || target.closest("[data-action]")?.dataset?.action;
    switch (actionName) {
      case "clearInput":
        this.clearInput();
      break;
    }
    this.emit("click", event, this);
  }
  clearInput() {
    this.input.value = "";
    this.emit("clearInput", this);
    this.container.classList.remove("js-active-clear");
  }
  handleInput(event) {
    const value = event.target.value;
    if (value.length > 0) {
      this.container.classList.add("js-active-clear");
    } else {
      this.clearInput();
    }
    this.emit("input", event, this);
  }
}

export default Search;