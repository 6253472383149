let disableAnimationsTimeout = null

// При изменении ширины экрана отключает анимации, что бы ничего не дергалось, да и производительность повысилась.
const disableAnimations = () => {
  $('body').addClass('preload')

  clearTimeout(disableAnimationsTimeout)

  disableAnimationsTimeout = setTimeout(() => {
    $('body').removeClass('preload')
  }, 300)
}

disableAnimations()

$(window).on('resize', () => {
  disableAnimations()
})