const breakpoints = {
  phone: 570,
  small: 750,
  middle: 990,
  large: 1230,
  extra: 1500,
  /**
   * 
   * @param {String} size Название брейкпойнта
   * - phone: 570px
   * - small: 750px
   * - middle: 990px
   * - large: 1230px
   * - large: 1500px
   */
  currentGreaterThan(size) {
    return this[size] > window.innerWidth;
  },
}

export default breakpoints;