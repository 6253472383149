import { useFetchClient } from "@/js/other/useFetchClient";
import { catalogListDto, avtocatalogDto, productCardDto } from "./dto";

const client = useFetchClient(location.origin);

export class ProductRepository {
  /**
   * 
   * @param {catalogListDto} dto Данные для запроса верстки каталога
   * @returns {Promise<{ data, status }>}
   */
  static async getCatalog(dto) {
    return await client.get("/catalog/", dto, {
      headers: { "Content-Type": "text/html" },
    });
  }
  /**
   * 
   * @param {avtocatalogDto} dto Данные для запроса верстки автокаталога
   * @returns {Promise<{ data, status }>}
   */
  static async getAvtoCatalog(dto) {
    return await client.get(location.pathname, dto, {
      headers: { "Content-Type": "text/html" },
    });
  }
  /**
   * 
   * @param {productCardDto} dto Данные для запроса верстки карточки товара
   * @returns {Promise<{ data, status }>}
   */
  static async getProductCard(dto) {
    return await client.get(location.pathname, dto, {
      headers: { "Content-Type": "text/html" },
    });
  }
}