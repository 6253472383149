import { useFetchClient } from "../other/useFetchClient";

const client = useFetchClient(`${location.origin}/become-client/`);

export class OrganizationRepository {
  /**
   * 
   * @param {number} value 
   * @returns {object} { data: string|array, found: number, type: success|error }
   */
  static async inn(value) {
    const { data, status } = await client.get(`/`, { inn: value });
    if (status === "error") return {
      found: 0,
      data: "Сервис временно не доступен",
      type: "error"
    };

    const { data: items = [], found = 0, type } = data;

    return { data: items, found, type };
  }
  /**
   * 
   * @param {number} value 
   * @returns {object} { data: string|array, found: number, type: success|error }
   */
  static async bic(value) {
    const { data, status } = await client.get(`/`, { bic: value });
    if (status === "error") return { data: "Сервис временно не доступен", status };
    let { data: items, type } = data;

    if (Array.isArray(items)) {
      items = items[0];
    }

    return {
      data: items,
      status: type
    };
  }
}
