class Emitter {
  constructor() {
    this.listeners = {};
  }
  on(name, fn) {
    !this.listeners[name] && (this.listeners[name] = []);
    this.listeners[name].push(fn);
  }
  off(name, fn) {
    this.listeners[name] = this.listeners[name].filter((listenerFn) => listenerFn !== fn);
  }
  async emit(name) {
    const listeners = this.listeners[name];
    if (!listeners) return;
    await Promise.all(listeners.map((fn) => {
      return fn.call(
        null,
        ...Array.prototype.slice.call(arguments, 1)
      )
    }));
  }
}

function getEmitter() {
  return new Emitter();
}

export default getEmitter;
export { Emitter };