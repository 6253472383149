 /**
   * 
   * @param {object} data Объект с данным для запроса верстки
   * @param {string} data.find Поисковой запрос
   * @param {string} data.manuf Выбранные бренды
   * @param {string} data.f Показывает какой фильтр (бренды или применяемость) выбрали первым
   * @param {string} data.f_mark Выбранные марки
   * @param {string} data.f_model Выбранные модели
   * @param {string} data.stock Наличие на складе
   * @param {string} data.page Страница пагинации
   * @param {string} data.sort Значение сортировки
   * @param {string} data.count Значение "1" говорит, что нужно вернуть количество товаров по данным параметрам
   * @param {string?} data.hiddenManuf Id бренда. Параметр нужен для страницы бренда
   * @returns {object}
   */
export const catalogListDto = (data = {}) => {
  const dto = {
    find: data.find,
    manuf: data.manuf,
    f: data.f,
    f_mark: data.f_mark,
    f_model: data.f_model,
    isNaked: 1,
    stock: data.stock,
    page: data.page || 1,
    sort: data.sort,
    count: data.count,
    hiddenManuf: data.hiddenManuf,
  }

  return Object.entries(dto).reduce((acc, [key, value]) => {
    if (value !== undefined && value !== "") acc[key] = value;
    return acc;
  }, {});
}

 /**
   * 
   * @param {object} data Объект с данным для запроса верстки
   * @param {string} data.sort Значение сортировки
   * @returns {object}
   */
export const avtocatalogDto = (data = {}) => {
  if (!data.sort) return {};
  return {
    sort: data.sort,
  };
}

 /**
   * 
   * @param {object} data Объект с данным для запроса верстки
   * @param {string} data.sort Поисковой запрос
   * @returns {object}
   */
export const productCardDto = (data = {}) => {
  if (!data.sort) return {};
  return {
    sort: data.sort,
  };
}