import { raf } from "@/js/other/raf";
import { Emitter } from "@other/emitter.js"
/**
 * Компонентик модалки.
 * new Modal('order_callback') <- создаст экземпляр конкоретного окна
 * moooodal.open() <- откроет его
 * moooodal.close() <- закроет его
 *
 * Можно подписаться на первое окрытие(first_opened), открытие(opened) и закрытие(closed)
 *
 * Что бы пометить кнопку как триггер для открытия созданной модалки data-modal-trigger="{name}"
 * Что бы пометить кнопку как триггер для закрытия созданной модалки data-modal-close="{name}"
 * Сама модалка имеет свою разметочку, там ее обязательно зарегистрировать data-modal-target="{name}"
 */
export default class Modal extends Emitter {
  constructor(selector) {
    super();
    this.selector = selector;
    this.setTarget();
    this.event = new CustomEvent('is_open');
    this.context = "";
    this.init(selector);
  }

  init(selector) {
    this.initKeys();
    this.initClicks(selector);
  }

  initClicks(selector) {
    let clickedEl = null;
    $("body").on("click", `[data-modal-trigger="${selector}"]`, this.open.bind(this));
    $("body").on("click", `[data-modal-close="${selector}"]`, this.close.bind(this));
    $("body").on("mousedown touchstart", `[data-modal-overlay="${selector}"]`, (event) => {
      clickedEl = event.target;
    });
    $("body").on("mouseup touchend", `[data-modal-overlay="${selector}"]`, (event) => {
      if (event.target === clickedEl && clickedEl === event.currentTarget) {
        this.close();
      }
    });
  }

  initKeys() {
    $(document).on('keyup', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }

  open(event) {
    const x = window.scrollX,
      y = window.scrollY;
      
    this.emit("beforeOpen", {
      setContent: this.setContent.bind(this),
      target: this.target,
      triggerEl: event?.currentTarget
    });
    
    raf(() => {
      this.setTarget();
      if (!this.target) return;

      $(this.target).addClass("js-active");
      $(this.target).trigger('opened');
      this.target.setAttribute("tabindex", "-1");
      this.target.focus({
        preventScroll: true,
      });
      window.scrollTo(x, y);
      this.emit("open");
    });
  }

  close(e) {
    raf(() => {
      this.emit("beforeClose", { target: this.target });
      this.target = document.querySelector(`[data-modal-target="${this.selector}"]`);
      this.target?.removeAttribute("tabindex");
      $(this.target).removeClass("js-active");
      $(this.target).trigger('closed');
      this.emit("close");
    });
  }
  /**
   * @description При инициализации и при каждом открытии модалки мы ищем на странице соответствующий DOM-элемент.
   *              Бывают такие случаи, когда элемент на странице присутствует, но this.target уже не ссылается на него.
   *              Данный метод устраняет такую ситуацию.
   */
  setTarget() {
    if (this.target) return;
    this.target = document.querySelector(`[data-modal-target="${this.selector}"]`);
  }

  setTitle(titleText = "") {
    return this.setDataToEl("[data-modal-title]", titleText);
  }

  setContent(contentText = "") {
    return this.setDataToEl("[data-modal-content]", contentText);
  }

  setDataToEl(selector, content) {
    const el = $(this.target).find(selector);
    if (el) {
      el.html(content)
      this.#setElVisible(selector, content?.trim())
    }
    return this;
  }

  setTitleVisible(isVisible) {
    this.#setElVisible("[data-modal-title]", isVisible)
  }

  setContentVisible(isVisible) {
    this.#setElVisible("[data-modal-content]", isVisible)
  }

  #setElVisible(selector, isVisible) {
    $(this.target).find(selector)[
      isVisible ? "removeClass" : "addClass"
    ]("hidden");
  }
}


function _createModal() {
  const modals = {};

  return function create({ selector = "", title = "", content = "", titleCenter = false }) {
    if (modals[selector]) {
      return modals[selector];
    }

    const check = document.querySelector(`[data-modal-target="${selector}"]`);
    if (check) {
      modals[selector] = new Modal(selector);
      return modals[selector];
    }

    const modalWrapper = document.createElement("div");
    modalWrapper.setAttribute("data-modal-target", selector);
    modalWrapper.classList.add("group");
    modalWrapper.innerHTML = `
      <div
        class="fixed flex items-center justify-center h-full w-full top-0 left-0 right-0 bottom-0 invisible opacity-0 group-[.js-active]:opacity-100 group-[.js-active]:visible group-[.js-active]:bg-black-50 transition-all z-[250]"
        data-modal-overlay="${selector}"
      >
        <div data-modal class="relative flex flex-col gap-8 px-12 xs:px-20 py-16 bg-white rounded-4xl shadow-md translate-y-12 group-[.js-active]:translate-y-0 transition-transform delay-75">
          ${getTitle(title, titleCenter)}
          <button data-modal-close="${selector}" aria-label="Закрыть" class="absolute top-6 right-8 text-grey-medium hover:text-black transition-all cursor-pointer">
            <svg class="stroke-current" width="32" height="32">
              <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#close"></use>
            </svg>
          </button>

          <div data-modal-content class="${content?.trim() ? "" : "hidden"}">
            ${content}
          </div>
        </div>
      </div>
      
    `;
    document.body.append(modalWrapper);
    modals[selector] = new Modal(selector);
    return modals[selector];
  };
}

function getTitle(title = "", titleCenter = false) {
  const classnames = titleCenter ? " justify-center text-center xs:max-w-[330px] px-8 py-5 mx-auto" : "";
  const hiddenClass = title.trim() ? '' : 'hidden ';
  return `<div class="${hiddenClass}flex items-center${classnames} min-h-[44px] w-full py-4 text-3xl text-black font-bold" data-modal-title>${title}</div>`;
}

export const createModal = _createModal();