import {getAxios} from '@other/axios'
import "@js/parts/modals/thanks.modal";

let isFirstOrderOpen = true;
let isFirstQuestionsOpen = true;
let isApplicabilityCreated = false;
let isProductImageFullCreated = false;


const getTrigger = (str) => `[data-modal-trigger="${str}"]`;

// Обратный звонок
$('body').on('click', getTrigger('order_callback'), () => {
  if (!isFirstOrderOpen) return;
  isFirstOrderOpen = false;

  const js = import(/* webpackChunkName: "order_callback" */ '@parts/modals/header/order_callback/index.js');

  Promise.all([js]).then((res) => {
    const [js] = res
    js.inject();
  })
});

$("body").on("click", getTrigger("applicability-modal"), async (event) => {
  // const id = Math.random() > 0.5 ? 33308 : 65972;
  const productId = event.currentTarget.closest(".product").dataset.id;
  const {data} = await getAxios().then((axios) => axios.get(`/api/primenyemost/?productId=${productId}`));

  if (!isApplicabilityCreated) {
    isApplicabilityCreated = true;
    const js = await import(/* webpackChunkName: "applicability-modal" */ "@parts/modals/applicabilityModal");
    js.inject(data);
  } else {
    $("#applicability-modal").html(data);
  }
});

$("body").on("click", getTrigger("product-image-full"), async (event) => {
  // const id = Math.random() > 0.5 ? 33308 : 65972;
  const link = event.currentTarget.src.replace("preview_", "");

  if (!isProductImageFullCreated) {
    isProductImageFullCreated = true;
    const js = await import(/* webpackChunkName: "product-image-full" */ "@parts/modals/productImageFull");
    js.inject(link);
  } else {
    // $("#product-image-full").html(data);
  }
});

// Написать нам
$('body').on('click', getTrigger('questions'), () => {
  if (!isFirstQuestionsOpen) return
  isFirstQuestionsOpen = false

  const markup = getAxios().then((axios) => axios.get(`/ask-question/?isNaked=1`))
  const js = import(/* webpackChunkName: "questions" */ '@parts/modals/header/questions/index.js');

  Promise.all([markup, js]).then((res) => {
    const [markup, js] = res
    js.inject(markup.data)
  })
});