import { useFetchClient } from "@/js/other/useFetchClient";
import {
  createContractorDto,
  createRequisiteDto,
  createStoreDto,
  updateContractorDto,
  updateRequisiteDto,
  updateStoreDto,
  archiveDto,
} from "./dto";
import { mapResponse } from "@/js/repositories/cabinet/mapper";

const client = useFetchClient(`${location.origin}`);

export class CabinetRepository {
  /**
   * 
   * @param {createContractorDto|createRequisiteDto|createStoreDto} dto
   * @returns {Promise<{ data: object, status: 'success' | 'error', markup: string }>}
   */
  static async create(dto) {
    const response = mapResponse(await client.post("/become-client/", dto));

    return {
      ...response,
      markup: await fetchMarkupIfSuccess(response.status),
    }
  }
  /**
   * 
   * @param {updateContractorDto|updateRequisiteDto|updateStoreDto} dto
   * @returns {Promise<{ data: object, status: 'success' | 'error' }>}
   */
  static async update(dto) {
    return mapResponse(await client.post("/become-client/", dto));
  }
  /**
   * 
   * @param {archiveDto} dto
   * @returns {Promise<{ data: object, status: 'success' | 'error', markup: string }>}
   */
  static async archive(dto) {
    const response = mapResponse(await client.post("/become-client/", dto));

    return {
      ...response,
      markup: await fetchMarkupIfSuccess(response.status),
    }
  }
  /**
   * @param {boolean} isPriceVisible
   * @returns {string} html
   */
  static async setPriceVisibillity(isPriceVisible) {
    return await client.get("/account/nastroyki/", {
      viewprice: isPriceVisible ? 2 : 1
    }, {
      credentials: "include",
      headers: {
        "Content-Type": "text/html; charset=utf-8",
      }
    });
  }
}

/**
 * @param {"success" | "error"} status
 * @returns {Promise<string>}
 */
async function fetchMarkupIfSuccess(status) {
  if (status === "error") return "";

  const { status: statusMarkup, data } = await client.get("/account/", null, {
    credentials: "include",
    headers: {
      "Content-Type": "text/html; charset=utf-8",
    }
  });

  if (statusMarkup === "error") return "";
  return data;
}