/**
 * @description raf - RequestAnimationFrame
 * @param {Function} callback
 */
export const raf = (
  window.requestAnimationFrame       ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame    ||
  function( callback ){
    window.setTimeout(callback, 1000 / 60);
  }
)