export function mapResponse({ data, status }) {
  data = JSON.parse(data);
  if (status === "success") {
    if (data.response?.type === "error") {
      status = "error";
    }
    data = data.response.description;
  } else {
    data = "Сервис временно недоступен";
  }

  return { data, status };
}